import React from "react";
import Layout from "src/common/Layout";
import { graphql, Link } from "gatsby";
import { AboutUsPageQuery } from "types/graphql-types";
import Facts from "src/common/components/AboutUs/Facts";
import "react-json-pretty/themes/1337.css";
import { Grid, Typography } from "@material-ui/core";
import ContentfulImage from "src/common/components/ContentfulImage";
import styled from "styled-components";
import SEO from "src/common/components/seo";
import AnchorLink from "src/common/components/AnchorLink";
import History from "src/common/aboutus/History";
import PressTeaser from "src/common/aboutus/PressTeaser";

import ContentfulRichText from "src/common/components/ContentfulRichText";
import StoreFinder from "src/common/components/StoreFinder";
import { useIntl } from "gatsby-plugin-intl"

const StyledLink = styled(Link)(
  (props) => `
  display: inline-block;
  color: ${props.theme.palette.black.main};
  background-color: transparent;
  font-size: 16px;
  border: 1px solid ${props.theme.palette.black.main};
  padding: ${props.theme.spacing(2, 4)};
  text-decoration: none;
  margin-right: ${props.theme.spacing(1)}px;
  &:focus {
    color: ${props.theme.palette.black.main}
  }
`
);

const aboutUs = ({
  data: { contentfulAboutUsPage, allContentfulNews, contentfulPressNewsPage, contentfulFooter, contentfulHeader },
}: {
  data: AboutUsPageQuery;
}) => {
  if (!contentfulAboutUsPage || !contentfulPressNewsPage || !allContentfulNews || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulAboutUsPage, contentfulPressNewsPage, allContentfulNews, contentfulFooter, contentfulHeader"
    );
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl()

  const {
    pageConfig,
    brandDescription,
    brandHeadline,
    storeTitle,
    storeSubtitle,
    storeSearchLabel,
    storeImage,
    activeTimeline,
  } = contentfulAboutUsPage;

  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      stageContent={
        <StageWrapper>
          <ContentfulImage
            url={pageConfig!.headerImage!.file!.url!}
          ></ContentfulImage>
        </StageWrapper>
      }
    >
      <SEO
        title={pageConfig!.seoConfig!.title!}
        description={pageConfig!.seoConfig!.description!}
        image={pageConfig!.headerImage?.file?.url!}
      />

      <ContentWrapper container justify="space-between">
        <LinkWrapper item xs={12} sm={3}>
          {/* <AnchorLink to="/about-us/#history">Firmengeschichte</AnchorLink> */}
          <AnchorLink to="/about-us/#facts">  {intl.formatMessage({ id: "NumbersAndFacts" })}</AnchorLink>
          <AnchorLink to="/about-us/#stores">  {intl.formatMessage({ id: "storeFinder" })}</AnchorLink>
        </LinkWrapper>
        <TextWrapper item xs={12} sm={8}>
          <Headline variant="h1" gutterBottom>
            {pageConfig!.title!.title}
          </Headline>
          <RichTextContainer>
            <ContentfulRichText
              json={contentfulAboutUsPage?.childContentfulAboutUsPageRichtextRichTextNode?.json}
              entryLinkPrefix="/about-us"
              showInitial
            />
          </RichTextContainer>
          <Typography variant="h3" component="h2">
            {brandHeadline}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {brandDescription?.internal.content}
          </Typography>

          <StyledLink to={"/brands/"}>
            {contentfulAboutUsPage.brandButtonTitle}
          </StyledLink>
        </TextWrapper>
      </ContentWrapper>
      {activeTimeline && <History />}
      <Facts
        contentfulAboutUsPage={contentfulAboutUsPage}
        facts={contentfulAboutUsPage?.factsList!}
      />
      <StoreFinder
        headline={storeTitle!}
        subHeadline={storeSubtitle!}
        label={storeSearchLabel!}
        image={storeImage!.file!.url!}
      />
      <PressTeaser
        pressTitle={contentfulAboutUsPage.pressTitle}
        pressSubtitle={contentfulAboutUsPage.pressSubtitle}
        pressMoreLabel={contentfulAboutUsPage.pressMoreLabel}
        nodes={allContentfulNews.nodes}
        downloads={contentfulPressNewsPage.downloads}
      />
    </Layout>
  );
};

export default aboutUs;

export const query = graphql`
  query AboutUsPage ($locale:String!){
    contentfulAboutUsPage (node_locale: {eq: $locale}){
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
        }
        seoConfig {
          title
          description
          image {
            localFile {
              publicURL
            }
            file {
              url
            }
          }
        }
      }
      brandButtonTitle
      brandDescription {
        internal {
          content
        }
      }
      brandHeadline
      historySupertitle
      historyTitle
      historyEntries {
        title
        year
        text
      }
      activeTimeline
      factsTitle
      factsOtherYearsLabel
      factsOtherYears {
        file {
          url
        }
      }
      storeTitle
      storeSubtitle
      storeSearchLabel
      storeImage {
        file {
          url
        }
      }
      pressTitle
      pressSubtitle
      pressMoreLabel
      factsList {
        title
        valuePrefix
        value
        subtitle
      }
      childContentfulAboutUsPageRichtextRichTextNode {
        json
      }
    }
    allContentfulNews(sort: { fields: creationDate, order: DESC }, limit: 2, filter: {node_locale: {eq: $locale}}) {
      nodes {
        id
        slug
        title
        description
        creationDate
        image {
          file {
            url
          }
        }
        creationDate
      }
    }
    contentfulPressNewsPage (node_locale: {eq: $locale}){
      downloads {
        title
        file {
          url
          fileName
          contentType
        }
        localFile {
          publicURL
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

const StageWrapper = styled("div")(
  ({ theme }) => `
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;

  padding:${theme.spacing(8, 3, 0)};
  div {
    width: 100%;
  }
  ${theme.breakpoints.up("md")} {
    padding:${theme.spacing(15, 7, 0)};
    div {
      width: 66%;
    }
  }

  ${theme.breakpoints.up("lg")} {
    padding:${theme.spacing(15, 7, 0)};
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100%;
  }
`
);
const ContentWrapper = styled(Grid)(
  ({ theme }) => `
  padding:${theme.spacing(0, 3)};
  max-width: 1280px;
    margin: 0 auto;

  ${theme.breakpoints.up("md")} {
    padding:${theme.spacing(0, 7)};
  }
  ${theme.breakpoints.up("lg")} {
    padding:${theme.spacing(0, 7)};
  }
`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
  background-color: #fff;
  z-index: 10;
  padding: ${theme.spacing(3, 0)};
  order: 1;
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(3, 0, 10, 3)};
    margin-top: -${theme.spacing(15)}px;
    order:2;
  }
  ${theme.breakpoints.up("md")} {
    margin-top: -${theme.spacing(20)}px;
    padding: ${theme.spacing(7, 0, 10, 7)};

  }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)}px;
  ${theme.breakpoints.up("md")} {
  margin-bottom: ${theme.spacing(8)}px;
  }
`
);

const LinkWrapper = styled(Grid)(
  ({ theme }) => `
  order: 2;
  border-top: 1px solid ${theme.palette.divider};
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(7, 0)};
  padding-top: ${theme.spacing(4.5)}px;

  ${theme.breakpoints.up("sm")} {
    margin-top: ${theme.spacing(15)}px;
    order: 0;
    padding-top: ${theme.spacing(4.5)}px;

  }
`
);

const RichTextContainer = styled("div")`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`;
